<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- User Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Field: Vorname -->
          <b-col cols="12" md="4">
            <!-- Vorname -->
            <validation-provider
              #default="validationContext"
              name="Vorname"
              rules="required"
            >
              <b-form-group :label="$t('First Name')" label-for="vorname">
                <b-form-input
                  id="vorname"
                  v-model="employeeLocal.vorname"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Nachname -->
          <b-col cols="12" md="4">
            <!-- Vorname -->
            <validation-provider
              #default="validationContext"
              name="Nachname"
              rules="required"
            >
              <b-form-group :label="$t('Last Name')" label-for="nachname">
                <b-form-input
                  id="nachname"
                  v-model="employeeLocal.nachname"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="Emailadresse"
              rules="required|email"
            >
              <b-form-group :label="$t('Email Address')" label-for="emailadresse">
                <b-form-input
                  id="emailadresse"
                  :disabled= true
                  v-model="employeeLocal.emailadresse"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Role -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="validationContext"
              name="Role"
              rules="required"
            >
              <b-form-group 
                  :label="$t('User Role')"
                label-for="user-role"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="employeeLocal.userrole"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-role"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t("Save") }}
      </b-button>

      <b-button @click="showMsgDeleteConfirm" variant="outline-secondary" class="ml-1">
        <span class="d-none d-sm-inline">Remove</span>
        <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
      </b-button>
      </b-form>

      
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { toRefs } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from "@validations";
import useEmployeeHandler from "../useEmployeeHandler";
import i18n from '@/libs/i18n'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  setup(props, { emit }) {
    const { employeeLocal, resetEmployeeLocal, onSubmit } = useEmployeeHandler(
      toRefs(props),
      emit
    );

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEmployeeLocal, props.clearEmployeeData);

    return {
      employeeLocal,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    };
  },
  methods: {
    showMsgDeleteConfirm() {
      this.$bvModal
        .msgBoxConfirm(i18n.t('Are you sure you want to delete this employee?'), {
          title: i18n.t('Confirm'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: i18n.t('Yes'),
          cancelTitle: i18n.t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$emit('remove-employee');
          }
        })
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
