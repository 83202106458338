<template>
  <b-overlay :show="show" opacity="0.5" rounded="sm">
    <component :is="employee === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="employee === undefined">
        <h4 class="alert-heading">Error fetching user data</h4>
        <div class="alert-body">
          No user found with this user id. Check
          <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
            User List
          </b-link>
          for other users.
        </div>
      </b-alert>

      <employee-edit-tab-account
        :employee="employee"
        :role-options="roleOptions"
        @update-employee="updateEmployee"
        @remove-employee="removeEmployee"
        class="mt-2 pt-75"
      />
    </component>
  </b-overlay>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BToast,
  BFormInvalidFeedback,
  BOverlay
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import employeeStoreModule from "../employeeStoreModule";
import EmployeeEditTabAccount from "./EmployeeEditTabAccount.vue";
import { useToast } from "vue-toastification/composition";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BToast,
    BFormInvalidFeedback,
    EmployeeEditTabAccount,
    ToastificationContent,
    BOverlay
  },
  data() {
    return {
      show: false,
    };
  },
  setup(props, context) {
    const $toast = useToast();
    const STAFF_STORE_MODULE_NAME = "app-employee";

    // Register module
    if (!store.hasModule(STAFF_STORE_MODULE_NAME))
      store.registerModule(STAFF_STORE_MODULE_NAME, employeeStoreModule);

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(STAFF_STORE_MODULE_NAME))
        store.unregisterModule(STAFF_STORE_MODULE_NAME);
    });

    const roleOptions = [
      { label: "admin", value: "admin" },
      { label: "employee", value: "employee" },
    ];

    const blankEmployee = {
      id: null,
      vorname: "",
      nachname: "",
      emailadresse: "",
      gender: "Mann",
      userrole: "employee",
    };
    const employee = ref(JSON.parse(JSON.stringify(blankEmployee)));

    store
      .dispatch("app-employee/fetchEmployee", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        employee.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          employee.value = undefined;
        }
      });

    return {
      employee,
      roleOptions,
    };
  },
  methods: {
    removeEmployee() {
      this.show = !this.show;
      store
        .dispatch("app-employee/removeEmployee", { id: this.employee.id })
        .then(() => {
          router.push({ name: "employees-list" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("Deleted"),
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.show = !this.show;
        });
    },
    updateEmployee(employeeData) {
      this.show = !this.show;
      store
        .dispatch("app-employee/updateEmployee", employeeData)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          router.push({ name: "employees-list" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("Updated"),
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.show = !this.show;
        });
    },
  },
};
</script>

<style>
</style>
