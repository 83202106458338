import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmployees(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/mitarbeiter', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmployee(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/mitarbeiter/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/mitarbeiter', employeeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEmployee(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/mitarbeiter/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmployee(ctx, employee) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/mitarbeiter/${employee.id}`, employee)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetPassword(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/mitarbeiter/reset-password/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}