import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function useEmployeeHandler(props, emit) {
  // ------------------------------------------------
  // employeeLocal
  // ------------------------------------------------
  const employeeLocal = ref(JSON.parse(JSON.stringify(props.employee.value)))
  const resetEmployeeLocal = () => {
    employeeLocal.value = JSON.parse(JSON.stringify(props.employee.value))
  }
  watch(props.employee, () => {
    resetEmployeeLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const employeeData = JSON.parse(JSON.stringify(employeeLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.employee.value.id) emit('update-employee', employeeData.value)
    else emit('add-employee', employeeData.value)

    // Close sidebar
    //emit('update:is-employee-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------


  return {
    employeeLocal,
    resetEmployeeLocal,

    // UI
    onSubmit,
  }
}